import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  Button,
} from '@mui/material';
import { getOperatorData } from '../api';

// Helper function to format date in (mm-dd-yyyy) format
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const CheckoutPage = () => {
  const loc = useLocation();
  const [vehicleImage, setVehicleImage] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // State variables for query parameters
  const [vehicleName, setVehicleName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dayCnt, setDayCnt] = useState('');
  const [reservationAmount, setReservationAmount] = useState('');
  const [smartWaiverUrl, setSmartWaiverUrl] = useState('');
  const [reservationHash, setReservationHash] = useState('');

  // Handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch operator data and set vehicle image
  useEffect(() => {
    const queryParams = new URLSearchParams(loc.search);
    const operatorParam = queryParams.get('operator');
    const smartWaiverUrlParam = queryParams.get('smartwaiver_url');
    const vehicleImageUrl = queryParams.get('vehicle_image_url');

    // Extract and set query parameter values
    setVehicleName(queryParams.get('vehicle_name') || 'Unknown Vehicle');
    setStartDate(formatDate(queryParams.get('start_date')));
    setEndDate(formatDate(queryParams.get('end_date')));
    setDayCnt(queryParams.get('dayCnt'));
    setReservationAmount(queryParams.get('reservation_amount'));
    setSmartWaiverUrl(smartWaiverUrlParam !== 'null' ? smartWaiverUrlParam : '');
    setReservationHash(queryParams.get('reservation_hash'));
    // Fetch operator data
    if (operatorParam) {
      getOperatorData(operatorParam).then(() => {
        localStorage.setItem('operator', operatorParam);
      });
    }

    // Set vehicle image
    if (vehicleImageUrl) {
      setVehicleImage(vehicleImageUrl);
    }
  }, [loc.search]);

  const fleet = JSON.parse(localStorage.getItem('fleet')) || {};

  const handleSignWaiverClick = () => {
    if (smartWaiverUrl) {
      window.location.href = smartWaiverUrl;
    }
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: fleet.menu_bkg_color || '#333' }}>
        <Toolbar>
          <Box
            component="img"
            src={fleet.web_logo || 'default_logo.png'}
            alt="Menu Logo"
            sx={{
              backgroundColor: fleet.web_bkg_color || '#000',
              padding: '8px',
              borderRadius: '4px',
              height: '75px',
              objectFit: 'contain',
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid>
        <Box>
          <Paper elevation={1} style={{ padding: '16px' }}>
            <Typography variant="h3">Reservation Confirmed!</Typography>
          </Paper>
        </Box>
      </Grid>

      <Grid container spacing={2} style={{ padding: '16px' }}>
        {isMobile ? (
          <>
            <Grid item xs={12}>
              <Box
                component="img"
                src={vehicleImage}
                alt="Vehicle Image"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  marginBottom: '16px',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={2} style={{ padding: '16px' }}>
                <Typography variant="h6">Details</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography><b>Reservation Confirmation ID:</b> {reservationHash}</Typography>
                <Typography><b>Reservation Total:</b> ${reservationAmount}</Typography>
                <Typography><b>Vehicle Type:</b> {vehicleName}</Typography>
                <Typography><b>Number of Days:</b> {dayCnt}</Typography>
                <Typography><b>Start Date:</b> {startDate}</Typography>
                <Typography><b>End Date:</b> {endDate}</Typography>
              </Paper>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <Box
                component="img"
                src={vehicleImage}
                alt="Vehicle Image"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  objectFit: 'contain',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={2} style={{ padding: '16px' }}>
                <Typography variant="h6" sx={{ wordWrap: 'break-word' }}>
                  Amplify Your Ride<br />
                  10625 Serenbe Ln, Palmetto, GA 30268<br />
                  770-282-9924
                </Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="h6">Details</Typography>
                <Divider style={{ margin: '8px 0' }} />
                <Typography><b>Reservation Confirmation ID:</b> {reservationHash}</Typography>
                <Typography><b>Reservation Total:</b> ${reservationAmount}</Typography>
                <Typography><b>Vehicle Type:</b> {vehicleName}</Typography>
                <Typography><b>Number of Days:</b> {dayCnt}</Typography>
                <Typography><b>Start Date:</b> {startDate}</Typography>
                <Typography><b>End Date:</b> {endDate}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Box
                component="img"
                src="https://assets.getroamr.app/AmplifyYourRide/AYR_QR_redirector_tmp_AYR01.png"
                alt="App QR Code"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  objectFit: 'contain',
                }}
              />
            </Grid>
          </>
        )}
            {smartWaiverUrl && (
        <Box display="flex" justifyContent="center" marginTop="16px">
          <Button
            onClick={handleSignWaiverClick}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#00B894',
              color: 'white',
              borderRadius: '24px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
              alignSelf: 'center',
              marginTop: '16px',
              padding: '0 16px',
              '&:hover': {
                backgroundColor: '#00A983',
              },
            }}
          >
            Sign Required Waiver Now
          </Button>
        </Box>
      )}
      </Grid>
    </div>
  );
};

export default CheckoutPage;
